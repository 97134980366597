import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationItem, AnimationOptions } from 'ngx-lottie/src/symbols';
import { DialogMessageData } from './dialog-message.model';

const successPath = "/assets/anim/success.json";
const errorPath = "/assets/anim/error.json";

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent implements OnInit {

  private animationItem: AnimationItem;
  animOptions: AnimationOptions = {
    loop: false,
    autoplay: true,
    path: successPath,
  };

  constructor(public dialogRef: MatDialogRef<DialogMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessageData, 
    private ngZone: NgZone) { }

  ngOnInit(): void {
    if(!this.data?.success){
      this.animOptions = {
        ...this.animOptions,
        path: errorPath,
      };
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  playAnimation(message: string): void {
    this.ngZone.runOutsideAngular(() => this.animationItem.play());
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
