import { Component } from '@angular/core';
import { AppMockup } from './models/AppMockup.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'sitoMP';

  currentState = 1;

  // colors = ["#FF0000", "#00FF00", "#000000"];
  // colorSelected = this.colors[0];
  appMockup: AppMockup = null;

  constructor(){

  }
  
  ngOnInit(): void {
  }

}
