<div class="bg-light">
    <div class="container">
      <div class="row py-5">
        <div class="col-lg-12 text-center">
          <h2 class="white-text">Vuoi saperne di più?</h2>
          <br />
          <h5>Contattaci e richiedici <b>GRATIS</b> il tuo preventivo</h5>
          <br />
          <br />
        </div>
  
        <div class="col-lg-8 offset-lg-2">
          <div class="hubspot-form-custom text-center">
            <a class="btn btn-primary" href="https://i54fqbt9bs4.typeform.com/to/VIlgX3Nz" target='_blank'>Compila il preventivo</a>
            <!-- <app-typeform></app-typeform> -->
            <!-- <app-subscribe-form
              [showAllFields]="true"
              [confirmButtonClass]="'btn-dark'"
              (onSuccess)="onSubscriptionSuccess($event)"
              (onFailure)="onSubscriptionError($event)"
            ></app-subscribe-form> -->
          </div>
        </div>
      </div>
    </div>
  </div>