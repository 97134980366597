import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { LottieModule } from 'ngx-lottie';
import { DeviceDetectorModule } from 'ngx-device-detector';

import player from 'lottie-web';
import { SubscribeFormComponent } from './components/subscribe-form/subscribe-form.component';
import { HeroComponent } from './components/home/hero/hero.component';

import { MatDialogModule } from '@angular/material/dialog';
import { DialogMessageComponent } from './components/dialog-message/dialog-message.component';
import { ParallaxDirective } from './utils/parallax.directive';
import { TimerComponent } from './components/home/timer/timer.component';
import { StickyDirective } from './utils/sticky.directive';
import { HomeService } from './services/home.service';
import { PreventivoComponent } from './components/preventivo/preventivo.component';
import { PromoComponent } from './components/promo/promo.component';
import { Angular2ImageGalleryModule } from 'angular2-image-gallery';
import { CategoriesComponent } from './components/categories/categories.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { LoadedDataDirective } from './utils/loaded-data.directive';
import { TypeformComponent } from './components/typeform/typeform.component';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: 'AIzaSyA9S7DY0khhn9JYcfyRWb1F6Rd2rwtF_mA'
};

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeroComponent,
    SubscribeFormComponent,
    DialogMessageComponent,
    ParallaxDirective,
    StickyDirective,
    LoadedDataDirective,
    TimerComponent,
    PreventivoComponent,
    PromoComponent,
    CategoriesComponent,
    NotFoundComponent,
    CookiePolicyComponent,
    TypeformComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    SwiperModule,
    FontPickerModule,
    AppRoutingModule,
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    LottieModule.forRoot({ player: playerFactory }),
    DeviceDetectorModule,
    MatDialogModule,
    Angular2ImageGalleryModule
  ],
  providers: [
    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG
    },
    HomeService
  ],
  entryComponents: [DialogMessageComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
