import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

enum CategoryTypeEnum{
  PortoneSezionale = 1,
  Basculante = 2,
  PortoneSezionaleLaterale = 3,
  Serranda = 4
}

enum MediaTypeEnum{
  Image = 1,
  Cover = 2,
  Video = 3
}

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({opacity: 1}),
          animate('500ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class CategoriesComponent implements OnInit {

  CategoryTypeEnum = CategoryTypeEnum;
  MediaTypeEnum = MediaTypeEnum;
  selectedCategory: CategoryTypeEnum;
  list = [];

  videoLoaded = false;
  constructor() { }

  private ll = [{
    url:'assets/img/portone-sezionale/video.webm',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Video
  },{
    url:'assets/img/portone-sezionale/cover-1.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Cover
  },{
    url:'assets/img/portone-sezionale/1.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale/cover-2.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Cover
  },{
    url:'assets/img/portone-sezionale/2.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale/3.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale/4.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale/5.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale/6.jpg',
    category: CategoryTypeEnum.PortoneSezionale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale-laterale/video.webm',
    category: CategoryTypeEnum.PortoneSezionaleLaterale,
    type: MediaTypeEnum.Video
  },{
    url:'assets/img/portone-sezionale-laterale/cover-1.jpg',
    category: CategoryTypeEnum.PortoneSezionaleLaterale,
    type: MediaTypeEnum.Cover
  },{
    url:'assets/img/portone-sezionale-laterale/1.jpg',
    category: CategoryTypeEnum.PortoneSezionaleLaterale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale-laterale/2.jpg',
    category: CategoryTypeEnum.PortoneSezionaleLaterale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale-laterale/3.jpg',
    category: CategoryTypeEnum.PortoneSezionaleLaterale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/portone-sezionale-laterale/4.jpg',
    category: CategoryTypeEnum.PortoneSezionaleLaterale,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/video.webm',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Video
  },{
    url:'assets/img/basculante/cover-1.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Cover
  },{
    url:'assets/img/basculante/1.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/2.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/3.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/4.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/5.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/6.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/basculante/7.jpg',
    category: CategoryTypeEnum.Basculante,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/serranda/video.webm',
    category: CategoryTypeEnum.Serranda,
    type: MediaTypeEnum.Video
  },{
    url:'assets/img/serranda/cover-1.jpg',
    category: CategoryTypeEnum.Serranda,
    type: MediaTypeEnum.Cover
  },{
    url:'assets/img/serranda/1.jpg',
    category: CategoryTypeEnum.Serranda,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/serranda/2.jpg',
    category: CategoryTypeEnum.Serranda,
    type: MediaTypeEnum.Image
  },{
    url:'assets/img/serranda/3.jpg',
    category: CategoryTypeEnum.Serranda,
    type: MediaTypeEnum.Image
  }];

  ngOnInit(): void {
    // this.list = [...this.ll];
    this.onSelectCategory(CategoryTypeEnum.PortoneSezionale)
  }

  onSelectCategory(selected: CategoryTypeEnum){
    this.videoLoaded = false;
    this.selectedCategory = selected;
    this.list = [];
    setTimeout(()=>{
      this.list = this.ll.filter(item => item.category == selected);
    }, 50);
  }

onDataLoaded(){
  setTimeout(()=>{
    this.videoLoaded = true;
  }, 350);
}

}
