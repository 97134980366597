import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-preventivo',
  templateUrl: './preventivo.component.html',
  styleUrls: ['./preventivo.component.scss']
})
export class PreventivoComponent implements OnInit {

  constructor(public dialogService: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  onSubscriptionSuccess(message: string){
    console.log(message)
    const dialogRef = this.dialogService.open(DialogMessageComponent, {
      // maxWidth: '300px',
      width: 'auto',
      data: {success: true, message: message}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  onSubscriptionError(message: string){
    console.log(message)
    const dialogRef = this.dialogService.open(DialogMessageComponent, {
      width: 'auto',
      data: {success: false, message: message}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

}
