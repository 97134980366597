<div class="content" role="main">
  <div class="navbar-container sticky-top d-block bg-light">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="navbar navbar-expand-lg">
            <a
              class="navbar-brand d-flex justify-content-between align-items-center"
              [routerLink]="['/']"
            >
              <img
                width="56"
                height="56"
                src="assets/img/logo.png"
                alt="logo-mp"
                class="img-fluid"
              />
              <b class="ml-1 text-logo">Automazioni Passini Maurizio</b>
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fas fa-bars"></i>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav">
                <!--<li class="nav-item active">
                                <a class="nav-link" href="index.html">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="partner/index.html">Partner Program</a>
                            </li>-->
                <li class="nav-item">
                  <a class="btn btn-link text-dark m-3" [routerLink]="['/']"
                    >Home</a
                  >
                  <!-- <a
                    class="btn btn-link text-dark m-3"
                    [routerLink]="['/promo']"
                    >Promozioni</a
                  > -->
                  <a
                    class="btn btn-link text-dark m-3"
                    [routerLink]="['/']"
                    [fragment]="'contacts'"
                    >Contatti</a
                  >
                  <a
                    class="btn btn-primary rounded-pill text-white m-3"
                    [routerLink]="['/preventivo']"
                    >Richiedi preventivo</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>

<div class="bg-dark">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-md-6 pt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 text-light">
              <h4 class="text-primary">Automazioni Passini Maurizio</h4>
              <br />
              <h5>
                Installazione, riparazione, manutenzione e assistenza su
                cancelli, serrande e portoni sezionali in tutte le zone di Roma.
              </h5>
              <br />
              <p>
                Un team di esperti del settore che lavorano sul campo da oltre
                30 anni con amore e dedizione. Nel corso degli anni, la ditta
                &egrave; diventata un punto di riferimento non solo nella
                citt&agrave; di Roma ma in tutta la provincia, grazie
                all'affidabilit&agrave; dei servizi e alla qualit&agrave; dei
                marchi trattati.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 flex-grow-1 p-0">
        <div class="overlay bg-dark position-absolute h-100 w-100 o-75"></div>
        <div class="footer"></div>
        <!-- <img class="img-fluid " src="assets/img/footer.jpg" alt="footer" /> -->
      </div>
    </div>
  </div>
</div>

<hr class="m-0"/>
<div class="container-fluid bg-dark pt-2">
  <div class="row align-items-center">
    <div class="col-12 col-sm py-3 text-left text-sm-center">
      <a [routerLink]="['/']">
        <img width="50" height="auto" src="assets/img/logo.png" alt="" />
      </a>
    </div>
    <div class="col-12 col-sm py-3 text-center">
      <small class="text-muted"
        ><p>Passini Maurizio - P.Iva 09105761002</p>
        <p>Copyright 2020 &copy; tutti i diritti riservati.</p></small
      >
    </div>
    <div class="col-12 col-sm py-3 text-right text-sm-center">
      <small class="text-muted">
        <!-- <a
          href="https://www.iubenda.com/privacy-policy/67017001"
          class="text-light mr-1"
          title="Privacy Policy"
          >Privacy Policy</a
        > -->
        <a href="https://www.iubenda.com/privacy-policy/40838133" class="iubenda-white iubenda-embed" title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
        <a
          [routerLink]="['/cookie-policy']"
          class="ml-1 iubenda-white iubenda-embed"
          title="Cookie Policy"
          >Cookie Policy</a
        ></small
      >
    </div>
  </div>
</div>
<!-- <div class="footer-small">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <a
          href="https://www.iubenda.com/privacy-policy/67017001"
          class="iubenda-white iubenda-embed"
          title="Privacy Policy "
          >Privacy Policy</a
        >
        <script type="text/javascript">
          (function (w, d) {
            var loader = function () {
              var s = d.createElement("script"),
                tag = d.getElementsByTagName("script")[0];
              s.src = "https://cdn.iubenda.com/iubenda.js";
              tag.parentNode.insertBefore(s, tag);
            };
            if (w.addEventListener) {
              w.addEventListener("load", loader, false);
            } else if (w.attachEvent) {
              w.attachEvent("onload", loader);
            } else {
              w.onload = loader;
            }
          })(window, document);
        </script>
        <a
          href="https://www.iubenda.com/privacy-policy/67017001/cookie-policy"
          class="iubenda-white iubenda-embed"
          title="Cookie Policy "
          >Cookie Policy</a
        >
        <script type="text/javascript">
          (function (w, d) {
            var loader = function () {
              var s = d.createElement("script"),
                tag = d.getElementsByTagName("script")[0];
              s.src = "https://cdn.iubenda.com/iubenda.js";
              tag.parentNode.insertBefore(s, tag);
            };
            if (w.addEventListener) {
              w.addEventListener("load", loader, false);
            } else if (w.attachEvent) {
              w.attachEvent("onload", loader);
            } else {
              w.onload = loader;
            }
          })(window, document);
        </script>
      </div>
    </div>
  </div>
</div> -->
