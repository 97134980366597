import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppMockup } from '../models/AppMockup.model';

const controllerUrl = `/api/index.php?route=`;

@Injectable()
export class HomeService {
  private _id: number = null;
  constructor(private http: HttpClient) {}
  insertViever(
    id: number
  ): Observable<any> {
    if(id){
      this._id = id;
      const baseUrl = location?.origin ?? "https://barbieri.app"
      return this.http.post(baseUrl + controllerUrl + "brand/contact/display", {
        tkn: "@2wsdFvg_8okmG5-strVtopMNb@_!",
        cid: id
      });
    }
  }

  updateViever(
    settings: AppMockup
  ): Observable<any> {
    const id = this._id ?? -1;
    const baseUrl = location?.origin ?? "https://barbieri.app"
    return this.http.post(baseUrl + controllerUrl + "brand/contact/settings", {
      tkn: "@2wsdFvg_8okmG5-strVtopMNb@_!",
      cid: id,
      settings: settings
    });
  }
}
