import { Directive, ElementRef, HostListener } from '@angular/core';

declare const $: any;

@Directive({
  selector: '[appSticky]'
})
export class StickyDirective {

  constructor(private eleRef: ElementRef) {
    $(this.eleRef.nativeElement).addClass("go-to-black-friday")
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event) {
    var elementHeight = $(this.eleRef.nativeElement).height();
    if (this.isElementInView(this.eleRef.nativeElement, false, elementHeight)) {
      $(this.eleRef.nativeElement).addClass("go-to-black-friday")
    }else{
      $(this.eleRef.nativeElement).removeClass("go-to-black-friday")
    }
  }

  isElementInView(element, fullyInView, offset = 0) {
    var pageTop = $(window).scrollTop();// + offset;
    var pageBottom = pageTop + $(window).height();
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).height();

    if (fullyInView === true) {
      return pageTop < elementTop && pageBottom > elementBottom;
    } else {
      return (elementTop <= pageBottom) && (elementBottom >= pageTop) && ((elementBottom + offset + 50) >= pageBottom);
    }
  }
}