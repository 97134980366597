import { Directive, Input, ElementRef, HostListener, HostBinding, OnInit, AfterViewInit, Output, EventEmitter } from "@angular/core";

declare const $: any;

@Directive({
  selector: "[loadedData]",
})
export class LoadedDataDirective implements AfterViewInit{
  @Output() onLoaded = new EventEmitter()
  
  constructor(private eleRef: ElementRef) {
  }
  
  ngAfterViewInit() {
      this.eleRef.nativeElement.onloadeddata = (event) => {
          this.onLoaded.emit();
      };
  }
}
