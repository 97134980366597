<!-- Portfolio -->
<div class="container-fluid bg-dark">
  <div class="container py-5">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-12">
        <div class="row">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="col"
          >
            <!-- <a href="#" class="btn btn-primary mr-2 mb-2 category" data-rel="all">All</a> -->
            <button
              (click)="onSelectCategory(CategoryTypeEnum.PortoneSezionale)"
              [ngClass]="
                selectedCategory == CategoryTypeEnum.PortoneSezionale
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              "
              class="btn mr-2 mb-2"
            >
              Portone sezionale
            </button>
            <button
              (click)="onSelectCategory(CategoryTypeEnum.Basculante)"
              [ngClass]="
                selectedCategory == CategoryTypeEnum.Basculante
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              "
              class="btn mr-2 mb-2"
            >
              Basculante
            </button>
            <button
              (click)="
                onSelectCategory(CategoryTypeEnum.PortoneSezionaleLaterale)
              "
              [ngClass]="
                selectedCategory == CategoryTypeEnum.PortoneSezionaleLaterale
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              "
              class="btn mr-2 mb-2"
            >
              Portone sezionale laterale
            </button>
            <button
              (click)="onSelectCategory(CategoryTypeEnum.Serranda)"
              class="btn mb-2"
              [ngClass]="
                selectedCategory == CategoryTypeEnum.Serranda
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              "
            >
              Serranda
            </button>
          </div>
        </div>
        <div class="my-3"></div>
        <div class="container-fluid position-relative p-0 min-vh-30">
          <div *ngIf="!(videoLoaded && list.length > 0)" [@enterAnimation] class="bg-dark min-vh-30 spinner-wrapper overlay-no-data">
            <div class="margin-bottom">
              <span class="spinner-section-far"></span>
            </div>
          </div>
        <div class="row">
          <div [hidden]="!videoLoaded" *ngFor="let item of list" class="col-12 mb-4" [ngClass]="item.type == MediaTypeEnum.Cover ? 'col-sm-8 col-md-6':'col-sm-4 col-md-3'">
            <video loadedData (onLoaded)="onDataLoaded()"
              *ngIf="item.type == MediaTypeEnum.Video; else imgTemplate"
              class="img-fluid rounded"
              playsinline
              autoplay
              muted
              loop
            >
              <source [src]="item.url" type="video/webm"/>
            </video>
            <ng-template #imgTemplate>
              <img class="img-fluid rounded" [src]="item.url" alt="" />
            </ng-template>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
