<div [ngSwitch]="data.success">
  <div *ngSwitchCase="true">
    <h1 mat-dialog-title>Congratulazioni!</h1>
    <div mat-dialog-content>
      <p>Nei prossimi giorni verrai contattato sulla mail da te fornita!</p>
    </div>
  </div>

  <div *ngSwitchDefault>
    <h1 mat-dialog-title>Ops!</h1>
    <div mat-dialog-content>
      <p [innerHTML]="data.message"></p>
    </div>
  </div>
  <ng-lottie class="lottie-class"
    [options]="animOptions"
    (animationCreated)="animationCreated($event)"
  ></ng-lottie>
</div>

<div mat-dialog-actions>
  <button class="btn btn-primary" (click)="onClose()">Ok</button>
</div>
