import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PreventivoComponent } from './components/preventivo/preventivo.component';
import { PromoComponent } from './components/promo/promo.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  // {
  //   path: 'promo',
  //   pathMatch: 'full',
  //   component: PromoComponent
  // },
  {
    path: 'preventivo',
    pathMatch: 'full',
    component: PreventivoComponent
  },{
    path: 'cookie-policy',
    pathMatch: 'full',
    component: CookiePolicyComponent
  },{
    path: '**',
    redirectTo: '404'
  },{
    path: '404',
    pathMatch: 'full',
    component: NotFoundComponent
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
