<div class="container-fluid position-relative vh-100 hero">
  <!-- <div class="overlay"></div> -->
  <!-- <div *ngIf="!videoLoaded" [@enterAnimation] class="bg-primary w-100 h-100 overlay-no-video"></div> -->
  <!-- <video loadedData (onLoaded)="onVideoLoaded()"
    class="w-100 h-100"
    playsinline="playsinline"
    autoplay="autoplay"
    muted="muted"
    loop="loop"
  > 
  <source src="assets/img/automatic-gates.mp4" type="video/mp4" />
</video> -->

<div class="container h-100">
    <div class="d-flex h-100 text-center align-items-center">
      <div class="w-100 content text-white">
        <h2 class="font-weight-bold">Installazione e riparazioni su cancelli, serrande e portoni sezionali in tutta Roma</h2>
        <p class="lead mb-0">Affidati a dei professionisti del settore e scegli la qualit&agrave; delle soluzioni che proponiamo.</p>
      </div>
    </div>
  </div>
</div>
<!-- End -->

<!-- PAIN 2 -->
<div class="container-fluid py-5">
    <div class="row">
      <div class="col-12 col-sm mb-3 mb-sm-0 order-sm-1">
        <img
          src="assets/img/backpain.jpeg"
          class="img-fluid rounded w-100"
        />
      </div>
      <div
        class="col-12 col-sm mb-sm-0 d-flex flex-column justify-content-center align-items-center text-center"
      >
        <h3 class="text-dark">
          Sei <strong class="font-weight-bold">stanco</strong> di dover faticare
          per
          <span class="text-primary">aprire il tuo cancello o serranda</span>?
        </h3>
        <p>
          Noi sappiamo come <span class="text-primary">risolvere</span> il tuo
          problema.
        </p>
        <!-- <a href="#learn-more" class="btn btn-primary btn-lg">Learn More</a> -->
      </div>
    </div>
</div>

<!-- PAIN 1 -->
<div class="container-fluid bg-light py-5">
    <div class="row">
      <div class="col-12 col-sm mb-5 mb-sm-0">
        <img
          src="assets/img/tired.jpg"
          class="img-fluid rounded w-100"
        />
      </div>
      <div
        class="col-12 col-sm mb-sm-0 d-flex flex-column justify-content-center align-items-center text-center"
      >
        <h3 class="text-dark">
          Sei <b>stufo</b> di vedere personale
          <span class="text-primary">poco qualificato</span>?
        </h3>
        <p>
          Sei sicuro di voler ancora spendere un patrimonio per riparazioni
          continue senza mai risolvere completamente il problema?
        </p>
        <a [routerLink]="['/']"
        [fragment]="'moreInfo'" class="btn btn-outline-primary">Rivolgiti a professionisti</a>
      </div>
    </div>
</div>

<div id="contacts"></div>
<div
  appSticky
  class="container-fluid py-3 bg-dark text-light d-flex justify-content-between align-items-center black-friday price-section"
>
  <div class="flex-grow-1">
    <h2>
      Chiamaci subito al
      <strong><a href="tel:+393388478350">3388478350</a></strong
      >!
    </h2>
    <h5 class="black-friday-hidre">
      Professionisti a disposizione h24 e 7 giorni su 7
    </h5>
  </div>
  <!-- <div class="black-friday-show text-center">
    <app-timer [smallComponent]="true" class="text-light w-100"></app-timer>
    <a class="btn btn-primary btn-sm w-100" href="#moreInfo" role="button"
      >Visualizza</a
    >
  </div> -->
</div>

<div class="container-fluid py-5">
  <div class="row">
    <div class="col-12 col-sm mb-5 mb-sm-0">
      <img
        src="assets/img/garage-door-brown.jpg"
        class="img-fluid rounded w-100"
      />
    </div>
    <div
      class="col-12 col-sm mb-sm-0 d-flex flex-column justify-content-center align-items-center text-center"
    >
      <h3 class="text-dark">
        <span class="text-primary">Riqualifica</span> il tuo garage o il tuo cancello
      </h3>
      <p>
        Donagli un aspetto da fare invidia e assicurati il comfort di un'installazione automatizzata
      </p>
      <a [routerLink]="['/preventivo']" class="btn btn-outline-primary">Contattaci per un preventivo</a>
    </div>
  </div>
</div>



<!-- TRANSFORM -->
<div class="container-fluid bg-primary p-3">
  <div class="row m-3">
    <div class="col-12 text-center">
      <h3 class="text-white">
        <span class="text-dark">Trasforma</span> il tuo vecchio garage in uno
        pi&ugrave; <span class="text-dark">funzionale</span>
      </h3>
      <p>Evita di <b>faticare</b> e <b>risparmia</b> sui costi di manutenzione, garantendoti ad un <b>prezzo super conveniente</b> una comodit&agrave; unica.</p>
    </div>
  </div>
  <div class="row p-3 justify-content-center">
    <div class="col-12 col-lg-8 col-md-10">
      <img-comparison-slider class="w-100 text-center">
        <img
          class="rounded img-fluid"
          slot="before"
          src="assets/img/after.jpg"
        />
        <img
          class="rounded img-fluid"
          slot="after"
          src="assets/img/before.jpeg"
        />
      </img-comparison-slider>
    </div>
  </div>
  <p class="text-muted text-center">Scorri a <b>sinistra</b> o <b>destra</b> per vedere il confronto tra <b>prima</b> e <b>dopo</b></p>
</div>

<div id="moreInfo"></div>
<div class="container-fluid">
  <div class="container py-5">
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-6 card">
        <div class="card-body">
          <h2 data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            Affidati a professionisti del settore
          </h2>
          <hr class="m-0" />
          <ul class="px-3 py-0 m-0">
            <li><i class="fas fa-check"></i>Pronto Intervento</li>
            <li><i class="fas fa-check"></i>Esperienza pluritrentennale</li>
            <li><i class="fas fa-check"></i>Qualità</li>
            <li><i class="fas fa-check"></i>Assistenza h24</li>
            <li><i class="fas fa-check"></i>Convenienza</li>
            <li><i class="fas fa-check"></i>Personale qualificato</li>
          </ul>
          <a
            class="btn btn-outline-dark rounded-pill m-1"
            [routerLink]="['/preventivo']"
            >Richiedi preventivo</a
          >
        </div>
      </div>
      <div class="col-12 m-2 d-block d-sm-none"></div>
      <div class="col-sm-12 p-0 col-md-6 text-center">
        <img
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          class="img-fluid rounded"
          src="assets/img/specialist.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-light">
  <div class="container py-5 text-center">
    <h3>Chiama subito per un primo intervento immediato</h3>
    <p>Pronto Intervento Riparazione Roma, si effettuano riparazioni nell’immediato.</p>
      <div class="row my-2">
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-sm-12 col-md-3 my-3">
              <i class="fas fa-home fa-5x text-primary"></i>
              <div class="m-3">
              <h3 class="text-dark">Riparazione a domicilio</h3>
              <p>Riparazione a domicilio per abitazioni, negozi, uffici e garage.</p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-sm-12 col-md-3 my-3">
              <i class="far fa-clock fa-5x text-primary"></i>
              <div class="m-3">
              <h3 class="text-dark">H24</h3>
              <p>Operativi 7 giorni su 7, 24 ore su 24, feriali e festivi inclusi.</p></div>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-sm-12 col-md-3 my-3">
              <i class="fas fa-users fa-5x text-primary"></i>
              <div class="m-3">
              <h3 class="text-dark">Tecnici Professionisti</h3>
              <p>Personale esperto, competente e soprattutto disponibile.</p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-sm-12 col-md-3 my-3">
              <i class="fas fa-wrench fa-5x text-primary"></i>
              <div class="m-3">
              <h3 class="text-dark">Servizi</h3>
              <p>Riparazione di cancelli, avvolgibili, porte basculanti e serrande per negozi e garage.</p>
            </div>
          </div>
      </div>
  </div>
</div>

<app-categories></app-categories>



<!-- <div class="container py-5" id="demo">
  <app-wizard></app-wizard>
</div> -->

<!-- TESTIMONIALS -->
<div class="container-fluid arrows-secondary bullets-secondary bg-light">
  <swiper
    class="custom-navigation"
    [config]="swiperConfig"
    [(index)]="currentSlide"
  >
  <div class="container py-5 text-center">
    <div class="row vh-50 justify-content-center align-items-center">
      <div class="col-12 col-sm-12 col-md-8">
        <blockquote class="blockquote">
          <i *ngFor="let item of [1,2,3,4,5]"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="fas fa-star text-primary"
          ></i>
          <div class="m-1"></div>
          <p
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="display-4"
          >
            <sup class="text-primary">"</sup>Personale qualificato e disponibile.<sup class="text-primary">"</sup>
          </p>
          <div class="m-2"></div>
          <img
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="rounded-circle"
            width="50px"
            height="50px"
            src="assets/img/roberta.jpg"
            alt=""
          />
          <div class="m-1"></div>
          <footer
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="blockquote-footer"
          >
            <cite title="Source Title">Roberta</cite>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
  <div class="container py-5 text-center">
    <div class="row vh-50 justify-content-center align-items-center">
      <div class="col-12 col-sm-12 col-md-8">
        <blockquote class="blockquote">
          <i *ngFor="let item of [1,2,3,4,5]"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="fas fa-star text-primary"
          ></i>
          <div class="m-1"></div>
          <p
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="display-4"
          >
            <sup class="text-primary">"</sup>Super conveniente e di ottima qualit&agrave;!<sup class="text-primary">"</sup>
          </p>
          <div class="m-2"></div>
          <img
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="rounded-circle"
            width="50px"
            height="50px"
            src="assets/img/marco.jpg"
            alt=""
          />
          <div class="m-1"></div>
          <footer
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="blockquote-footer"
          >
            <cite title="Source Title">Marco</cite>
          </footer>
        </blockquote>
      </div>
    </div>
  </div>
    <div class="container py-5 text-center">
      <div class="row vh-50 justify-content-center align-items-center">
        <div class="col-12 col-sm-12 col-md-8">
          <blockquote class="blockquote">
            <i *ngFor="let item of [1,2,3,4]"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="fas fa-star text-primary"
          ></i>
          <i
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            class="fas fa-star-half-alt text-primary"
          ></i>
            <div class="m-1"></div>
            <p
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              class="display-4"
            >
              <sup class="text-primary">"</sup>Ottimo servizio e personale gentile<sup class="text-primary">"</sup>
            </p>
            <div class="m-2"></div>
            <img
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              class="rounded-circle"
              width="50px"
              height="50px"
              src="assets/img/alessandro.jpg"
              alt=""
            />
            <div class="m-1"></div>
            <footer
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              class="blockquote-footer"
            >
              <cite title="Source Title">Alessandro</cite>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  </swiper>

</div>




<!-- Clients / Sponsors -->
<div class="container-fluid clients">
  <div class="container py-5  text-center">
    <h3>Concessionari Hormann e installatori</h3>
      <div class="row">
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
            <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="https://www.hormann.it/" target="_blank">
                <img class="img-fluid img-saturation-hover" src="assets/img/hoermann-logo.svg" alt="hormann">
            </a>
        </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
              <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="https://faac.it/" target="_blank">
                  <img class="img-fluid img-saturation-hover" src="assets/img/faac-logo.png" alt="faac">
              </a>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
              <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="https://www.bft-automation.com/it_IT/" target="_blank">
                  <img class="img-fluid img-saturation-hover p-3" src="assets/img/bft-logo.svg" alt="bft">
              </a>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
              <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="https://www.niceforyou.com/it" target="_blank">
                  <img class="img-fluid img-saturation-hover p-3" src="assets/img/nice-logo.png" alt="nice">
              </a>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
              <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="https://www.came.com/it/" target="_blank">
                  <img class="img-fluid img-saturation-hover p-4" src="assets/img/came-logo.svg" alt="came">
              </a>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
              <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="https://www.rogertechnology.it/it/" target="_blank">
                  <img class="img-fluid img-saturation-hover p-4" src="assets/img/roger-logo.png" alt="rogertechnology">
              </a>
          </div>
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" class="col-6 col-sm-4 col-md-3  my-2">
              <a class="h-100 w-100 d-flex align-items-center justify-content-center" href="http://www.fadini.net/index.asp?language=ITA" target="_blank">
                  <img class="img-fluid img-saturation-hover p-3" src="assets/img/fadini-logo.jpg" alt="fadini">
              </a>
          </div>
      </div>
  </div>
</div>


<div class="container-fluid">
  <div class="row py-3 justify-content-center">
    <h3>Ultimi lavori effettuati</h3>
  </div>
  <gallery 
  [flexBorderSize]="3" 
  [flexImageSize]="7"
  [maxRowsPerPage]="100">
</gallery>
</div>

<div class="bg-primary" id="infoRequest">
  <div class="container">
    <div class="row py-5">
      <div class="col-lg-12 text-center">
        <h2 >Vuoi saperne di più?</h2>
        <h5 class="text-light">Contattaci e richiedi altre informazioni</h5>
        <br />
      </div>

      <div class="col-lg-8 offset-lg-2">
        <div class="hubspot-form-custom text-center">
          <a class="btn btn-dark" href="https://i54fqbt9bs4.typeform.com/to/VIlgX3Nz" target='_blank'>
            Richiedi il tuo preventivo
            <span><i class="fa fa-heart text-danger heart-icon"></i></span>
          </a>
          <!-- <app-subscribe-form
            [showAllFields]="true"
            [confirmButtonClass]="'btn-dark'"
            (onSuccess)="onSubscriptionSuccess($event)"
            (onFailure)="onSubscriptionError($event)"
          ></app-subscribe-form> -->
        </div>
      </div>
    </div>
  </div>
</div>
