import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HomeService } from 'src/app/services/home.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':leave', [
          style({opacity: 1}),
          animate('500ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class HomeComponent implements OnInit {

  // @ViewChild('videoPlayer') videoPlayer: ElementRef;

videoLoaded = false;
// videoStarted = false;

// ngAfterViewInit() {
//     this.videoPlayer.nativeElement.onloadeddata = (event) => {
//         console.log('Video data is loaded.');
//         this.dataLoaded = true;
//     };

//     this.videoPlayer.nativeElement.onplaying = (event) => {
//         console.log('Video is no longer paused.');
//         this.videoStarted = true;
//     };
// }
  
  swiperConfig = {
    autoplay: true,
    // effect: 'fade',
    // fadeEffect: { crossFade: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  }
  currentSlide = 0;
  isMobile: boolean = false;

  constructor(public dialogService: MatDialog,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private service: HomeService
  ) {
    this.isMobile = this.deviceService.isMobile(); 
  }
  
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
    const id = params["promo"]; // get param
    // if(id){
    //   this.service.insertViever(id).subscribe(res => {

    //   })
    // }
  });
  }

  goToSlide(index: number){
    this.currentSlide = index;
  }

  onSubscriptionSuccess(message: string){
    console.log(message)
    const dialogRef = this.dialogService.open(DialogMessageComponent, {
      // maxWidth: '300px',
      width: 'auto',
      data: {success: true, message: message}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  onSubscriptionError(message: string){
    console.log(message)
    const dialogRef = this.dialogService.open(DialogMessageComponent, {
      width: 'auto',
      data: {success: false, message: message}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  onVideoLoaded(){
    console.log("video loaded")
    this.videoLoaded = true;
  }
}
