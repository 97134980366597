<div class="container">
  <div class="one_line_col">
    <h2 style="padding-top: 0">Cookie Policy di automazionipassini.it</h2>

    <p>
      Questo documento contiene informazioni in merito alle tecnologie che
      consentono a automazionipassini.it di raggiungere gli scopi descritti di seguito.
      Tali tecnologie permettono al Titolare di raccogliere e salvare
      informazioni (per esempio tramite l’utilizzo di Cookie) o di utilizzare
      risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente
      quando quest’ultimo interagisce con automazionipassini.it.
    </p>
    <p>
      Per semplicità, in questo documento tali tecnologie sono sinteticamente
      definite “Strumenti di Tracciamento”, salvo vi sia ragione di
      differenziare.<br />
      Per esempio, sebbene i Cookie possano essere usati in browser sia web sia
      mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni
      per dispositivi mobili, dal momento che si tratta di Strumenti di
      Tracciamento che richiedono la presenza di un browser. Per questo motivo,
      all’interno di questo documento il temine Cookie è utilizzato solo per
      indicare in modo specifico quel particolare tipo di Strumento di
      Tracciamento.
    </p>

    <p>
      Alcune delle finalità per le quali vengono impiegati Strumenti di
      Tracciamento potrebbero, inoltre richiedere il consenso dell’Utente. Se
      viene prestato il consenso, esso può essere revocato liberamente in
      qualsiasi momento seguendo le istruzioni contenute in questo documento.
    </p>

    <p>
      automazionipassini.it utilizza Strumenti di Tracciamento gestiti direttamente dal
      Titolare (comunemente detti Strumenti di Tracciamento “di prima parte”) e
      Strumenti di Tracciamento che abilitano servizi forniti da terzi
      (comunemente detti Strumenti di Tracciamento “di terza parte”). Se non
      diversamente specificato all’interno di questo documento, tali terzi hanno
      accesso ai rispettivi Strumenti di Tracciamento.<br />
      Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento
      simili possono variare a seconda di quanto impostato dal Titolare o da
      ciascun fornitore terzo. Alcuni di essi scadono al termine della sessione
      di navigazione dell’Utente.<br />
      In aggiunta a quanto specificato nella descrizione di ciascuna delle
      categorie di seguito riportate, gli Utenti possono ottenere informazioni
      più dettagliate ed aggiornate sulla durata, così come qualsiasi altra
      informazione rilevante - quale la presenza di altri Strumenti di
      Tracciamento - nelle privacy policy dei rispettivi fornitori terzi
      (tramite i link messi a disposizione) o contattando il Titolare.
    </p>

    <h3>
      Attività strettamente necessarie a garantire il funzionamento di
      automazionipassini.it e la fornitura del Servizio
    </h3>
    <p>
      automazionipassini.it utilizza Cookie comunemente detti “tecnici” o altri Strumenti
      di Tracciamento analoghi per svolgere attività strettamente necessarie a
      garantire il funzionamento o la fornitura del Servizio.
    </p>

    <h3>
      Altre attività che prevedono l’utilizzo di Strumenti di Tracciamento
    </h3>

    <h4>Misurazione</h4>
    <p>
      automazionipassini.it utilizza Strumenti di Tracciamento per misurare il traffico e
      analizzare il comportamento degli Utenti con l'obiettivo di migliorare il
      Servizio.
    </p>

    <ul class="for_boxes">
      <li>
        <div class="box_primary box_10 expand">
          <h3 class="expand-click w_icon_24 policyicon_purpose_7698925">
            Heat mapping e registrazione sessioni
          </h3>
          <div class="expand-content">
            <p>
              I servizi di Heat mapping sono utilizzati per individuare le aree
              di automazionipassini.it con le quali gli Utenti interagiscono con maggior
              frequenza, in modo da rilevare quali di esse attraggono maggior
              interesse. Questi servizi permettono di monitorare e analizzare i
              dati di traffico e servono a tener traccia del comportamento
              dell’Utente.<br />
              Alcuni di questi servizi potrebbero registrare le sessioni e
              renderle disponibili per visualizzarle in seguito.
            </p>

            <h4>Hotjar Heat Maps &amp; Recordings (Hotjar Ltd.)</h4>

            <div class="wrap">
              <p>
                Hotjar è un servizio di heat mapping e di registrazione delle
                sessioni fornito da Hotjar Ltd.<br />
                Hotjar rispetta gli header generici „Do Not Track”. Questo
                significa che il browser può indicare allo script di non
                raccogliere alcun dato dell’Utente. Si tratta di un'impostazione
                che è disponibile in tutti i principali browser. Maggiori
                <a
                  href="https://www.hotjar.com/legal/compliance/opt-out"
                  target="_blank"
                  rel="noopener"
                  >Informazioni sull'opt-out</a
                >
                da Hotjar sono disponibili qui.
              </p>
            </div>

            <p>
              Dati Personali trattati: Dati di utilizzo, Strumento di
              Tracciamento e varie tipologie di Dati secondo quanto specificato
              dalla privacy policy del servizio.
            </p>

            <p>
              Luogo del trattamento: Malta –
              <a
                href="https://www.hotjar.com/legal/policies/privacy"
                target="_blank"
                rel="noopener noreferrer"
                >Privacy Policy</a
              >
              –
              <a
                href="https://www.hotjar.com/legal/compliance/opt-out"
                target="_blank"
                rel="noopener"
                >Opt Out</a
              >.
            </p>
          </div>
        </div>
      </li>
      <li>
        <div class="box_primary box_10 expand">
          <h3 class="expand-click w_icon_24 policyicon_purpose_7080751">
            Statistica
          </h3>
          <div class="expand-content">
            <p>
              I servizi contenuti nella presente sezione permettono al Titolare
              del Trattamento di monitorare e analizzare i dati di traffico e
              servono a tener traccia del comportamento dell’Utente.
            </p>

            <h4>Google Analytics (Google LLC)</h4>

            <div class="wrap">
              <p>
                Google Analytics è un servizio di analisi web fornito da Google
                LLC (“Google”). Google utilizza i Dati Personali raccolti allo
                scopo di tracciare ed esaminare l’utilizzo di automazionipassini.it,
                compilare report e condividerli con gli altri servizi sviluppati
                da Google.<br />
                Google potrebbe utilizzare i Dati Personali per contestualizzare
                e personalizzare gli annunci del proprio network pubblicitario.
              </p>
            </div>

            <p>Dati Personali trattati: Cookie e Dati di utilizzo.</p>

            <p>
              Luogo del trattamento: Stati Uniti –
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                >Privacy Policy</a
              >
              –
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=it"
                onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=it'})"
                target="_blank"
                >Opt Out</a
              >.
            </p>

            <h4>Hotjar Form Analysis &amp; Conversion Funnels (Hotjar Ltd.)</h4>

            <div class="wrap">
              <p>
                Hotjar è un servizio di statistica fornito da Hotjar Ltd.<br />
                Hotjar rispetta gli header generici „Do Not Track”. Questo
                significa che il browser può indicare allo script di non
                raccogliere alcun dato dell’Utente. Si tratta di un'impostazione
                che è disponibile in tutti i principali browser. Maggiori
                <a
                  href="https://www.hotjar.com/legal/compliance/opt-out"
                  target="_blank"
                  rel="noopener"
                  >Informazioni sull'opt-out</a
                >
                da Hotjar sono disponibili qui.
              </p>
            </div>

            <p>
              Dati Personali trattati: Dati di utilizzo e Strumento di
              Tracciamento.
            </p>

            <p>
              Luogo del trattamento: Malta –
              <a
                href="https://www.hotjar.com/legal/policies/privacy"
                target="_blank"
                rel="noopener noreferrer"
                >Privacy Policy</a
              >
              –
              <a
                href="https://www.hotjar.com/legal/compliance/opt-out"
                target="_blank"
                rel="noopener"
                >Opt Out</a
              >.
            </p>
          </div>
        </div>
      </li>
    </ul>

    <h3 id="manage_cookies">
      Come gestire le preferenze e prestare o revocare il consenso
    </h3>
    <p>
      Esistono vari modi per gestire le preferenze relative agli Strumenti di
      Tracciamento e per prestare o revocare il consenso, ove necessario:
    </p>
    <p>
      Gli Utenti possono gestire le preferenze relative agli Strumenti di
      Tracciamento direttamente tramite le impostazioni dei propri dispositivi -
      per esempio, possono impedire l’uso o l’archiviazione di Strumenti di
      Tracciamento.
    </p>
    <p>
      In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento
      dipenda da consenso, l’Utente può prestare o revocare tale consenso
      impostando le proprie preferenze all’interno dell’informativa sui cookie o
      aggiornando tali preferenze tramite il widget delle impostazioni di
      tracciamento, se presente.
    </p>
    <p>
      Grazie ad apposite funzioni del browser o del dispositivo è anche
      possibile rimuovere Strumenti di Tracciamento precedentemente salvati.
    </p>
    <p>
      Altri Strumenti di Tracciamento presenti nella memoria locale del browser
      possono essere rimossi cancellando la cronologia di navigazione.
    </p>

    <p>
      Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti
      possono gestire le preferenze e revocare il consenso visitando il relativo
      link di opt out (qualora disponibile), utilizzando gli strumenti descritti
      nella privacy policy della terza parte o contattandola direttamente.
    </p>

    <h4>Individuare le impostazioni relative agli Strumenti di Tracciamento</h4>
    <p>
      Gli Utenti possono, per esempio, trovare informazioni su come gestire i
      Cookie in alcuni dei browser più diffusi ai seguenti indirizzi:
    </p>
    <ul>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://support.google.com/chrome/answer/95647?hl=it&amp;p=cpn_cookies"
          >Google Chrome</a
        >
      </li>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
          >Mozilla Firefox</a
        >
      </li>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"
          >Apple Safari</a
        >
      </li>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
          >Microsoft Internet Explorer</a
        >
      </li>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://support.microsoft.com/it-it/help/4027947"
          >Microsoft Edge</a
        >
      </li>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing"
          >Brave</a
        >
      </li>
      <li>
        <a
          rel="noopener nofollow"
          target="_blank"
          href="https://help.opera.com/latest/web-preferences/#cookies"
          >Opera</a
        >
      </li>
    </ul>
    <p>
      Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per
      applicazioni mobili disattivandoli tramite le apposite impostazioni del
      dispositivo, quali le impostazioni di pubblicità per dispositivi mobili o
      le impostazioni relative al tracciamento in generale (gli Utenti possono
      consultare le impostazioni del dispositivo per individuare quella
      pertinente).
    </p>

    <h3 id="owner_of_the_data">Titolare del Trattamento dei Dati</h3>
    <p>Automazioni Passini Maurizio</p>
    <p><strong>Indirizzo email del Titolare:</strong> automazionipassini@gmail.com</p>

    <p>
      Dal momento che l’uso di Strumenti di Tracciamento di terza parte su
      automazionipassini.it non può essere completamente controllato dal Titolare, ogni
      riferimento specifico a Strumenti di Tracciamento di terza parte è da
      considerarsi indicativo. Per ottenere informazioni complete, gli Utenti
      sono gentilmente invitati a consultare la privacy policy dei rispettivi
      servizi terzi elencati in questo documento.
    </p>
    <p>
      Data l'oggettiva complessità di identificazione delle tecnologie di
      tracciamento, gli Utenti sono invitati a contattare il Titolare qualora
      volessero ricevere ulteriori informazioni in merito all'utilizzo di tali
      tecnologie su automazionipassini.it.
    </p>
  </div>

  <div>
    <div>
      <h3>
        Definizioni e riferimenti legali
      </h3>
      <div>
        <h4>Dati Personali (o Dati)</h4>
        <p>
          Costituisce dato personale qualunque informazione che, direttamente o
          indirettamente, anche in collegamento con qualsiasi altra
          informazione, ivi compreso un numero di identificazione personale,
          renda identificata o identificabile una persona fisica.
        </p>

        <h4>Dati di Utilizzo</h4>
        <p>
          Sono le informazioni raccolte automaticamente attraverso automazionipassini.it
          (anche da applicazioni di parti terze integrate in automazionipassini.it), tra
          cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati
          dall’Utente che si connette con automazionipassini.it, gli indirizzi in
          notazione URI (Uniform Resource Identifier), l’orario della richiesta,
          il metodo utilizzato nell’inoltrare la richiesta al server, la
          dimensione del file ottenuto in risposta, il codice numerico indicante
          lo stato della risposta dal server (buon fine, errore, ecc.) il paese
          di provenienza, le caratteristiche del browser e del sistema operativo
          utilizzati dal visitatore, le varie connotazioni temporali della
          visita (ad esempio il tempo di permanenza su ciascuna pagina) e i
          dettagli relativi all’itinerario seguito all’interno
          dell’Applicazione, con particolare riferimento alla sequenza delle
          pagine consultate, ai parametri relativi al sistema operativo e
          all’ambiente informatico dell’Utente.
        </p>

        <h4>Utente</h4>
        <p>
          L'individuo che utilizza automazionipassini.it che, salvo ove diversamente
          specificato, coincide con l'Interessato.
        </p>

        <h4>Interessato</h4>
        <p>La persona fisica cui si riferiscono i Dati Personali.</p>

        <h4>Responsabile del Trattamento (o Responsabile)</h4>
        <p>
          La persona fisica, giuridica, la pubblica amministrazione e qualsiasi
          altro ente che tratta dati personali per conto del Titolare, secondo
          quanto esposto nella presente privacy policy.
        </p>

        <h4>Titolare del Trattamento (o Titolare)</h4>
        <p>
          La persona fisica o giuridica, l'autorità pubblica, il servizio o
          altro organismo che, singolarmente o insieme ad altri, determina le
          finalità e i mezzi del trattamento di dati personali e gli strumenti
          adottati, ivi comprese le misure di sicurezza relative al
          funzionamento ed alla fruizione di automazionipassini.it. Il Titolare del
          Trattamento, salvo quanto diversamente specificato, è il titolare di
          automazionipassini.it.
        </p>

        <h4>automazionipassini.it (o questa Applicazione)</h4>
        <p>
          Lo strumento hardware o software mediante il quale sono raccolti e
          trattati i Dati Personali degli Utenti.
        </p>

        <h4>Servizio</h4>
        <p>
          Il Servizio fornito da automazionipassini.it così come definito nei relativi
          termini (se presenti) su questo sito/applicazione.
        </p>

        <h4>Unione Europea (o UE)</h4>
        <p>
          Salvo ove diversamente specificato, ogni riferimento all’Unione
          Europea contenuto in questo documento si intende esteso a tutti gli
          attuali stati membri dell’Unione Europea e dello Spazio Economico
          Europeo.
        </p>

        <h4>Cookie</h4>
        <p>
          I Cookie sono Strumenti di Tracciamento che consistono in piccole
          porzioni di dati conservate all'interno del browser dell'Utente.
        </p>

        <h4>Strumento di Tracciamento</h4>
        <p>
          Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es.
          Cookie, identificativi univoci, web beacons, script integrati, e-tag e
          fingerprinting - che consenta di tracciare gli Utenti, per esempio
          raccogliendo o salvando informazioni sul dispositivo dell’Utente.
        </p>

        <hr />

        <h4>Riferimenti legali</h4>
        <p>
          La presente informativa privacy è redatta sulla base di molteplici
          ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento
          (UE) 2016/679.
        </p>
        <p>
          Ove non diversamente specificato, questa informativa privacy riguarda
          esclusivamente automazionipassini.it.
        </p>
      </div>
    </div>
  </div>

  <div>
    <p>Ultima modifica: 23 Marzo 2021</p>
  </div>
  <!-- /footer -->
</div>
